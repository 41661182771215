<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비유형" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비관리번호" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비명" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 tabvertical">
      <c-tab
        type="vertical"
        :tabItems="tabItems"
        :height.sync="height"
        v-model="tab"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :param="param"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>
1
<script>
export default {
  name: 'equipment-etc',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      equipinfo: {
        col1: '1사업장',
        col2: '저장소',
        col3: 'EQ-0000001',
        col4: '설비명1',
      },
      tab: 'equipmentGrade',
      tabItems: [
        { name: 'equipmentGrade', icon: 'grade', label: '등급 변경', component: () => import(`${'./equipmentGrade.vue'}`) },
        { name: 'equipmentBom', icon: 'location_city', label: '자재정보', component: () => import(`${'./equipmentBom.vue'}`) },
        { name: 'equipmentMoc', icon: 'published_with_changes', label: '관련 MOC', component: () => import(`${'./equipmentMoc.vue'}`) },
        { name: 'equipmentDrawing', icon: 'filter', label: '관련 도면', component: () => import(`${'./equipmentDrawing.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }
};
</script>